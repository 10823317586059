<template>
  <KTPortlet title="My Services" id="myServicesPortlet">
    <template slot="body" style="padding: 0;">
      <iframe
        id="myServicesIframe"
        style="border: none;"
        src="http://status.bettercorp.co.za/"
        :height="`${iframe.height}px`"
        scrolling="no"
      ></iframe>
    </template>
  </KTPortlet>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      iframe: {
        width: 100,
        height: 2000
      }
    };
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener("message");
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Service Status" }]);
    let self = this;
    window.addEventListener(
      "message",
      ev => {
        if (ev === undefined || ev === null) return;
        if (ev.data === undefined || ev.data === null) return;
        if (ev.data.nodePingContentChange !== true) return;

        self.iframe.width = ev.data.width;
        self.iframe.height = ev.data.height;
      },
      false
    );
  },
  methods: {}
};
</script>

<style>
#myServicesPortlet .kt-portlet__body {
  padding: 0;
}
</style>
